import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface BarChartType {
    apiData? : Object[];
    bg? : string;
    height? : Number
    barColors? : string[];
    props? : any;
    isFullscreen? : boolean;
}

const BarChart : React.FC <BarChartType> = ({apiData, bg, height=240, barColors, props, isFullscreen=false}) => {

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'bar',
            backgroundColor: bg || "transparent",
            height: height
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            labels: {
                enabled: false,
                style: {
                    fontSize: '10px',
                    fontFamily: 'inter',
                }
            },
            gridLineWidth: 0,
            dateTimeLabelFormats: {
                month: '%b %y', // Custom format for months
                year: '%Y' // Format for years if needed
            },
        },
        yAxis: {
            labels: {
                enabled: true,
                autoRotation: [45, 90],
                style: {
                    fontSize: '10px',
                    fontFamily: 'Verdana, sans-serif'
                }
            },
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            enabled: true,
            align: "left",
            itemStyle: {
                fontFamily: 'Inter'
            }
        },
        tooltip : {
            shared : true,
            formatter: function (this : any) {
              var date = this.x // + (5.5 * 3600 * 1000); // Add 5.5 hours (IST) to the timestamp
              var formattedDate = Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', date);
              var points = this.points.map(function (point : any) {
                  return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y + '</b>';
              });
              return '<b>' + formattedDate + '</b><br/>' + points.join('<br/>');
            }
          },
        series : [] as Object[],
        plotOptions: {
            bar: {
                pointWidth: 25
            }
        },
        credits: {
            enabled: false
        },
        exporting : {
            enabled : true,
            buttons: {
                contextButton: {
                    menuItems: [
                        'downloadJPEG', // Allow JPEG export
                        'separator', 
                        'downloadCSV'   // Allow CSV export
                    ]
                }
            }
        }
    });

    // Adjust chart height based on full-screen state
    useEffect(() => {
        const newHeight = isFullscreen ? 800 : height;
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                height: newHeight
            }
        }));
    }, [isFullscreen, height]);

    useEffect(() => {
        if (apiData) {
            setChartOptions({
                ...chartOptions,
                series: apiData.map((series : object, index : number) => {
                    if(barColors?.length){
                        return ({
                            ...series,
                            color : barColors[index]
                        })
                    } else {
                        return {
                            ...series
                        }
                    }
                }),
                ...props
            });
        }
    }, [apiData, props]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
};

export default BarChart;



// colors: [
//             '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
//             '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
//             '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
//             '#03c69b', '#00f194'
//         ],