import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface LineChartProps {
    apiData?: Object[];
    height?: number;
    bg?: string;
    props?: any;
    isFullscreen? : boolean;
}

const LineChart: React.FC<LineChartProps> = ({ apiData, height = 240, bg, props, isFullscreen=false }) => {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            backgroundColor: bg || 'transparent',
            height: height,
            zoomType: 'x',
        },
        title: {
            text: '',
        },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            labels : {
                enabled : true,
                // formatter: function(this : any) {
                //     return Highcharts.dateFormat('%H:%M', this.value + (5.5 * 3600 * 1000)); // Add 5.5 hours (IST) to the timestamp
                // }
            },
            dateTimeLabelFormats: {
                month: '%b %y', // Custom format for months
                year: '%Y' // Format for years if needed
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            crosshair: true
        },
        tooltip: {
            shared: true,
            valueSuffix: ' unit',
            headerFormat: '<b>{series.name}</b><br>',
            formatter: function (this : any) {
                var date = this.x;
                var formattedDate = Highcharts.dateFormat('%Y-%m-%d %H:%M', date);
                var points = this.points.map(function (point : any) {
                    return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y + '</b>';
                });
                return '<b>' + formattedDate + '</b><br/>' + points.join('<br/>');
            }
        },
        series: apiData || [],
        credits: {
            enabled: false,
        },
        label : {
            enabled : true
        },
        exporting : {
            enabled : true,
            buttons: {
                contextButton: {
                    menuItems: [
                        'downloadJPEG', // Allow JPEG export
                        'separator', 
                        'downloadCSV'   // Allow CSV export
                    ]
                }
            }
        }
    });

    // Adjust chart height based on full-screen state
    useEffect(() => {
        const newHeight = isFullscreen ? 800 : height;
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                height: newHeight
            }
        }));
    }, [isFullscreen, height]);

    useEffect(() => {
        if (apiData) {
            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: apiData,
                ...(props || {}),
            }));
        }
    }, [apiData, props]);


    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default LineChart;