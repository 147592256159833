import React, { useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer'

const Layout:React.FC<{children : React.JSX.Element}> = ({children}) => {

  useEffect(() => {
    // Scroll to the top of the page whenever the content (children) changes
    window.scrollTo(0, 0);
  }, [children]);
  return (
    <>
        <Navbar>
            <main>{children}</main>
        </Navbar>
        <Footer />
    </>
  )
}

export default Layout