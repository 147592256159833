import { useState, useEffect, useCallback } from 'react';
import DeviceOverviewAPI from '../../../api/DeviceOverview/DeviceOverviewAPI';

const UseDeviceOverview = (pageSize : number, page: number, textSearch : string) => {
  const [data, setData] = useState([]);

  const fetchTelemetryData = useCallback(async () => {
    try {
      const response = await DeviceOverviewAPI(pageSize, page, textSearch);
      setData(response?.data || []);
    } catch (error) {
      console.error('Error fetching telemetry data:', error);
    }
  }, [pageSize, page, textSearch]);

  useEffect(() => {
    fetchTelemetryData();
    const interval = setInterval(fetchTelemetryData, 300000);
    return () => clearInterval(interval);
  }, [fetchTelemetryData]);

  return data;
};

export default UseDeviceOverview;
