import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { TimeWindow } from '../../../Services/TimeWindow';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { MdFullscreen } from 'react-icons/md';

interface PlantViewTableLayoutProps {
  children: React.ReactNode;
  title: string;
  bg? : string;
  width: string[];
  height: string;
  px? : string;
  fullscreen? : boolean;
  paginationLimitProps? : number;

  timeWindow? : boolean;
  onTimeWindowChange?: (from: string, to: string, aggregate: string, interval : number) => void;
  onReset? : (Reset : boolean) => void;
}

const PlantViewTableLayout: React.FC<PlantViewTableLayoutProps> = ({ children, title, bg, width, height, px, timeWindow, fullscreen, paginationLimitProps=8, onTimeWindowChange, onReset }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tableRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState<number>(paginationLimitProps)
  // console.log(apiData)

  const handleFullScreen = () => {
    if (tableRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else {
        (tableRef.current as HTMLElement)?.requestFullscreen();
        setPaginationLimit(20); // Increase pagination limit in fullscreen mode
        setIsFullscreen(true);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setPaginationLimit(paginationLimitProps);
        setIsFullscreen(false); // Exited fullscreen
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [paginationLimitProps]);

  return (
    <Box
      ref={tableRef}
      w={width}
      h={height}
      borderRadius="5px"
      bgColor={useColorModeValue('white', 'gray.800')}
      p={3}
      px={px}
      mt={5}
      boxShadow="md"
      overflow={"auto"}
      bg = {bg}
    >
      <HStack height={"40px"} spacing={5}>
        <Box mt={-5}>
          {timeWindow && <IconButton
                  aria-label='Time Window'
                  icon={<RxCounterClockwiseClock />}
                  onClick={onOpen}
          />}
          <TimeWindow 
              isOpen={isOpen} 
              onClose={onClose} 
              onSave={onTimeWindowChange || (()=>{})} 
              onReset={onReset || (() => {})}
          />

        </Box>
        <Text 
         fontSize={"16px"}
         fontFamily={"inter"} 
         fontWeight={600} 
         color={useColorModeValue('#004F86', 'white')}
         letterSpacing={1}
         mt ={-5}
         >{title}</Text>
      </HStack>
      <Box as='main' pb={6} height={"full"} width={"full"} _dark={{color : "white"}}>
        {/* Pass the isFullscreen state to children */}
          {fullscreen? React.isValidElement(children) &&
            React.cloneElement(children as React.ReactElement<any>, {
            isFullscreen,
          }) : children}
          {fullscreen && <Icon 
          as={MdFullscreen}
          boxSize={5} 
          float={"right"} 
          pos={"relative"} 
          bottom={"42px"} 
          onClick={handleFullScreen}
          _fullScreen={{
            bottom : "45px"
          }}
          cursor={"pointer"}
          />}
      </Box>
    </Box>
  );
};

export default PlantViewTableLayout;
