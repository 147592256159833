import { Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";

interface ReportSelectorType {
    handleOptionChange : (option : any) => void
}

const ReportSelector : React.FC <ReportSelectorType> = ({handleOptionChange}) => {
    const handleChange = (option : any) => {
        handleOptionChange(option)
    }

    const options = [
        {value : 0, label : "Power Report"},
        {value : 1, label : "Energy Report"},
        {value : 2, label : "Inverter & DG Report"},
        {value : 3, label : "DG Runtime Report"},
        {value : 4, label : "Solar Saving Report"},

    ];

    return(
        <Box
            width={"full"}
        >
            <Flex width={"full"} justify={"center"}>
                <FormControl width={["300px", "400px"]}>
                    <FormLabel fontFamily={"inter"} fontWeight={200} fontSize={10} letterSpacing={1}>
                        Select Report
                    </FormLabel>
                    <Box fontWeight={600}>
                        <Select 
                            isMulti={false}
                            placeholder={"Select Report"}
                            closeMenuOnSelect={true}
                            variant="outline"
                            focusBorderColor="green.500"
                            options={options}
                            selectedOptionColorScheme="green"
                            onChange={handleChange}
                            defaultValue={options[0]}
                        />
                    </Box>
                </FormControl>
            </Flex>
        </Box>
    );
};

export default ReportSelector;