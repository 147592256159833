// src/Services/CustomerHandlingService.ts

import { jwtDecode } from 'jwt-decode';
import { GetCustomersAPI, GetCustomerAPI } from '../api/GetCustomersAPI';
import { Customer } from '../Context/CustomerOptionsContext';
import GetUserInfo, { GetPlantInfo } from '../api/Authentication/UserAPI';
import {CustomerGroupAPI, CustomersofGroupAPI} from '../api/Authentication/CustomerGroupAPI';

interface JwtHeader {
  lastName: string;
  customerId: string;
  userId : string;
}

let PlantOfCustomers = [] as any; // Golabally Declare for a customer has grp customer with plants (grp opf Asset)

const CustomerHandlingService = async (setCustomerOptions: (customers: Customer[]) => void, setSelectedCustomerID : (id: string) => void) => {
  const jwt = localStorage.getItem("token");
  let authority = "";
  let customerID = "";
  let userID = "";
  if (!jwt) {
    throw new Error("JWT not found in localStorage");
  }
  try {
    const Decoded : JwtHeader = jwtDecode<JwtHeader>(jwt);
    const {lastName , customerId, userId} = Decoded;
    authority = lastName;
    customerID = customerId;
    userID = userId;

    try {
      const response = await GetUserInfo(userID, jwt);
      authority = response?.data["groups"][0].name;
      localStorage.setItem("authority", authority);
      localStorage.setItem("currentUserTimeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);
    } catch (error) {
      throw new Error("Authority Is Not Present In Current User!")
    }
    
  } catch (error) {
    throw new Error("Last Name Not present in JWT")
  }

  
  if (authority === "Customer Administrators") {
    
    try {

      PlantOfCustomers = [];

      const CustomerGroupResponse = await CustomerGroupAPI(customerID, "CUSTOMER");
      const CustomerPlantAssetResponse = await CustomerGroupAPI(customerID, "ASSET");
      
      if(CustomerGroupResponse.length > 0) {

        localStorage.setItem("PortfolioType", JSON.stringify(CustomerGroupResponse));
        localStorage.setItem("SelectedPortfolioTypeId", CustomerGroupResponse[0].value);
        await GetPlantGroup(CustomerGroupResponse[0].value, setCustomerOptions, setSelectedCustomerID)
      };

      if(CustomerPlantAssetResponse && CustomerPlantAssetResponse.length > 0) {
        PlantOfCustomers.push(CustomerPlantAssetResponse);
          setCustomerOptions(PlantOfCustomers.flat(1) || ""); // CustomerOption is PLANT OPTIONS
          setSelectedCustomerID((PlantOfCustomers.flat(1))[0]?.value || "");  // SELECTED CUTOMER ID is PLANT ID
          if((PlantOfCustomers.flat(1))[0]?.value) {
            await  PlantTimeZoneSelection((PlantOfCustomers.flat(1))[0]?.value);
          }
      }
      
      // const response = await GetCustomersAPI(jwt);
      // if (response.length > 0) {
      //   setCustomerOptions(response); // CustomerOption is PLANT OPTIONS
      //   setSelectedCustomerID(response[0].value);  // SELECTED CUTOMER ID is PLANT ID
      // }


    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  } else if(authority === "Customer Users") {
    // console.log(authority, customerID, 8989898989)
    try {
      const response = await GetCustomerAPI(customerID, jwt);
      setCustomerOptions(response);
      setSelectedCustomerID(response[0].value);
    } catch (error) {
      console.error("Error fetching customer Data: ", error);
    }
  }
};

export default CustomerHandlingService;

export const GetPlantGroup = async (GroupId : string, setCustomerOptions : {(customer : Customer[]) : void}, setSelectedCustomerID : {(id : string) : void}, emptyOption? : boolean) => {
        if(emptyOption) {
          PlantOfCustomers = [];
        }
        const CustomersOfGroupAPI = await CustomersofGroupAPI(GroupId);

        if(CustomersOfGroupAPI && CustomersOfGroupAPI.length > 0) {
          for(var i = 0; i < CustomersOfGroupAPI.length; i++) {

            PlantOfCustomers.push(await CustomerGroupAPI(CustomersOfGroupAPI[i].id, "ASSET"));
          }
          setCustomerOptions(PlantOfCustomers.flat(1) || ""); // CustomerOption is PLANT OPTIONS
          setSelectedCustomerID((PlantOfCustomers.flat(1))[0]?.value || "");  // SELECTED CUTOMER ID is PLANT ID
          if((PlantOfCustomers.flat(1))[0]?.value) {
            await  PlantTimeZoneSelection((PlantOfCustomers.flat(1))[0]?.value);
          }
        }
}

export const PlantTimeZoneSelection = async (plantID : string) => {
  const plantInfo = await GetPlantInfo(plantID);
  if(plantInfo) {
    const description = plantInfo.additionalInfo.description;
    if(description) {
      const timeZone = JSON.parse(description).timeZone;
      if(timeZone) {
        localStorage.setItem("PlantTimeZone", timeZone);
      } else {
        localStorage.setItem("PlantTimeZone", "Asia/Kolkata");
      }
    }
  }
}
