import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface AreaSplineChartProps {
    apiData? : Object[];
    height? : number | string;
    bg? : string;
    props? : any;
    isFullscreen? : boolean;
}

const AreaSplineChart : React.FC <AreaSplineChartProps> = ({apiData, height="300px", bg, props, isFullscreen=false}) => {
    const [chartOption, setChartOptions] = useState({
        chart: {
            type: 'areaspline',
            height : height,
            backgroundColor : bg || "transparent"
        },
        title: {
            text: ''
        },
        xAxis: {
            type : "datetime",
            tickPixelInterval: 150,
            // labels : {
            //     formatter: function(this : any) {
            //         return Highcharts.dateFormat('%H:%M', this.value + (5.5 * 3600 * 1000)); // Add 5.5 hours (IST) to the timestamp
            //     }
            // }
        },
        yAxis: {
            title: {
                text: 'Sales'
            }
        },
        tooltip : {
            shared : true,
            formatter: function (this : any) {
                var date = this.x // + (5.5 * 3600 * 1000); // Add 5.5 hours (IST) to the timestamp
                var formattedDate = Highcharts.dateFormat('%Y-%m-%d %H:%M', date);
                var points = this.points.map(function (point : any) {
                    return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y + '</b>';
                });
                return '<b>' + formattedDate + '</b><br/>' + points.join('<br/>');
            }
        },
        series: [] as Object[],
        legend : {
            align : "left"
        },
        credits : {
            enabled : false
        },
        exporting : {
            enabled : true,
            buttons: {
                contextButton: {
                    menuItems: [
                        'downloadJPEG', // Allow JPEG export
                        'separator', 
                        'downloadCSV'   // Allow CSV export
                    ]
                }
            }
        }
    });

    // Adjust chart height based on full-screen state
    useEffect(() => {
        const newHeight = isFullscreen ? 800 : height;
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                height: newHeight
            }
        }));
    }, [isFullscreen, height]);

    useEffect(() => {
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            series : apiData || [{}],
            ...props
        }))
    }, [apiData, props])

    return (
        <HighchartsReact highcharts={Highcharts} options={chartOption} />
    );
};

export default AreaSplineChart;