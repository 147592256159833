import { 
  Box,
  Grid,
  GridItem,
  SimpleGrid,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink 
} from '@chakra-ui/react';
import GridCardIcon from "../assets/StatisticsCardImage/GridCardIcon.png";
import LatestValueCardLayout from '../components/Layouts/LatestValueCardLayout';
import ChartLayout from '../components/Layouts/ChartLayouts/ChartLayout';
import DigitalHorizontalBar from '../components/widgets/charts/DigitalHorizontalBar';
import { GiThunderball } from "react-icons/gi";
import CalculationCard from '../components/widgets/CalculationCard';
import LineChart from '../components/widgets/charts/LineChart';
import BG from '../assets/GridCardBG/BG.svg'
import GridIcon from '../assets/GridCardBG/GridIcon';
import { FcLineChart } from "react-icons/fc";
import { FaCaretRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import UseBatteryStatus from '../Services/Hooks/Battery/UseBatteryStatus';
import UsePlantCard from '../Services/Hooks/PlantView/UsePlantCard';
import { useTimeHandle } from '../Services/TimeWindowSetting';
import { useEffect } from 'react';
import UseBESSDaily from '../Services/Hooks/Battery/UseBESSDaily';
import UseManyDeviceManyKeysChart from '../Services/Hooks/UseManyDeviceManyKeysChart';
import ColumnChart from '../components/widgets/charts/ColumnChart';
import TopRibbon from '../components/TopRibbon';

const GridDashboard = () => {

    // ************************* Digital Bar Chart ******************

    var search = {
        devName : "Grid-Meter", // Grid Meter
        keys : "Bus_B_voltage_L1_L2,Bus_B_voltage_L2_L3,Bus_B_voltage_L3_L1"   // Line AMP Daata is not present in Grid-Meter in Durteck
    }
    const DigitalBarData = UseBatteryStatus(search) || [0,0,0,0,0,0];

    //******************************Grid Card*************** */

    var searchGridCard = '[{"columns" : "Key","values" : "Grid_Power_kW","status" : 0},{"columns" : "Key","values" : "Bus_B_frequency_L1","status" : 0},{"columns" : "Key","values" : "Grid_Daily_Energy_Export_kWh","status" : 1}, {"columns" : "Key","values" : "Grid_Daily_Energy_Import_kWh","status" : 0},{"columns" : "Key","values" : "SL_Bus_B_voltage_L1_L2","status" : 0}, {"columns" : "Key","values" : "AGC_AMP_L1","status" : 0}]'
    var DataLabel = ["Grid kW", "Grid Hz", "Consumtion kWh", "Feed kWh", "CO2 Saving Tons", "Line Voltage V", "Line Current A"]
    const PlantCardData = UsePlantCard(searchGridCard, DataLabel) || [[]];
    // console.log("PlantCardData", PlantCardData)

    // ********************* Grid Voltage & Hz *********************
    const {
        timeWindow: timeWindowGridVolt,
        handleTimeWindowChange: handleTimeWindowGridVoltChange,
        handleReset: GridVoltHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);

    
    var searchTagGridVolt = { 
        devName : "Grid", // cal
        keys : "Bus_B_voltage_L1_L2,Bus_B_voltage_L2_L3,Bus_B_voltage_L3_L1,Bus_B_frequency_L1",
        type : ["spline","spline","spline","spline"],
        name : ["line-1 V","line-2 V","line-3 V","Hz"]
    };
    const GridVoltData = UseBESSDaily(searchTagGridVolt, timeWindowGridVolt);
    // useEffect(() => {
    //     if (GridVoltData) {
    //         console.log("GridVoltData:", GridVoltData);
    //     }
    // }, [GridVoltData]);

    // ********************* Grid Power *********************
    const {
        timeWindow: timeWindowGridPower,
        handleTimeWindowChange: handleTimeWindowGridPowerChange,
        handleReset: GridPowerHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [5, "minute"]);

    
    var searchTagGridPower = { 
        devName : "Grid-Meter",
        keys : "Grid_Power_kW,Grid_Power_kVAR,Grid_Power_KVA",
        type : ["spline","spline","spline"],
        name : ["Power kW","Power kVAR"]
    };
    const GridPowerData = UseBESSDaily(searchTagGridPower, timeWindowGridPower);
    // useEffect(() => {
    //     if (GridPowerData) {
    //         console.log("GridPowerData:", GridPowerData);
    //     }
    // }, [GridPowerData]);

    // ********************* Grid Energy Flow *********************
    const {
        timeWindow: timeWindowGridEnergy,
        handleTimeWindowChange: handleTimeWindowGridEnergyChange,
        handleReset: GridEnergyHandleReset
    } = useTimeHandle(10, "hour", "AVG", [30, "minute"]);

    
    var searchTagGridEnergy = [{ 
        devName : "Grid-Meter",
        keys : "Grid_Daily_Energy_Export_kWh,Grid_Daily_Energy_Import_kWh",
        type : ["column","column"],
        name : ["Consumption kWh","Feed kWh"]
    },
    {
        devName : "Calculation",
        keys : "SL_Grid_Percent_kW,SL_INV_Load_Percent_kW",
        type : ["spline","spline"],
        name : ["Grid Penetration %","PV Penetration %"]   
    }];
    const GridEnergyColors = ["#7E7EC8", "#B3261E","#6ADBFFB2", "#19CA16"]
    const GridEnergyData = UseManyDeviceManyKeysChart(searchTagGridEnergy, timeWindowGridEnergy)?.map((series : object, index : number) => ({
        ...series,
        color : GridEnergyColors[index]
    }));
    // useEffect(() => {
    //     if (GridEnergyData) {
    //         console.log("GridEnergyData:", GridEnergyData);
    //     }
    // }, [GridEnergyData]);

    // ********************* Grid Current *********************
    const {
        timeWindow: timeWindowGridCurrent,
        handleTimeWindowChange: handleTimeWindowGridCurrentChange,
        handleReset: GridCurrentHandleReset
    } = useTimeHandle(10, "hour", "AVG", [5, "minute"]);

    
    var searchTagGridCurrent = { 
        devName : "AGC_4", // cal
        keys : "AGC_AMP_L1,AGC_AMP_L2,AGC_AMP_L3", // AMP isnt present in Durtek
        type : ["spline","spline","spline"],
        name : ["L1 Amps","L2 Amps","L3 Amps"]
    };
    const GridCurrentData = UseBESSDaily(searchTagGridCurrent, timeWindowGridCurrent);
    // useEffect(() => {
    //     if (GridCurrentData) {
    //         console.log("GridCurrentData:", GridCurrentData);
    //     }
    // }, [GridCurrentData]);


  return (
      <Box maxW="full" ml={10} px={{ base: 2, sm: 12, md: 17 }}>
        <Breadcrumb spacing="8px" separator={<FaCaretRight color="gray.500" />} mb={5}>
            <BreadcrumbItem color="rgba(0, 79, 134, 1)" fontSize={12}>
            <BreadcrumbLink>
                Home
            </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
            <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontSize={12} as={Link} to="/portfolio">
                Portfolio
            </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
            <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={12}>
                Sites
            </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
            <BreadcrumbLink color="rgba(0, 79, 134, 1)" fontWeight={600} fontSize={12} as={Link} to="/grid">
                Grid
            </BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>

{/* ****************** TOP RIBBON ************ */}

        <TopRibbon />

          <SimpleGrid
              mt={5}
              maxW={"8xl"}
              h={"min-content"}
              minChildWidth={["300px", "400"]}
              column={4}
              row={1}
              gap={1}
          >
              <GridItem colSpan={1} h={"100%"} mt={5}>
                      <LatestValueCardLayout
                          title={'Grid Power'}
                          deviceLabel='Grid-Meter'
                          telemetry='Grid_Power_kW'
                          titleColor='#003F6B'
                          stat={'5,000kW'}
                          unit={" kW"}
                          statColor={"#8842E0"}
                          width={["100%","100%",'80%']}
                          height='30%'
                          w={[59, 89]}
                          h={[59, 89]}
                          bg={"transparent"}
                          border={'2px solid #0FCB44'}
                          src={GridCardIcon}
                          value2={"Last updated just now"}
                          value2Color={'#9B9A9A'}
                          showArrow={false}
                          sparkline={<Box> {/* Add sparkline component here */} </Box>}
                      />
                      <LatestValueCardLayout
                          title={'Grid Consumption'}
                          deviceLabel='Grid-Meter'
                          telemetry='Grid_Daily_Energy_Export_kWh'
                          titleColor='#003F6B'
                          stat={'1,000,000kW'}
                          unit=' kWh'
                          width={["100%","100%",'80%']}
                          height='30%'
                          statColor={"#8842E0"}
                          w={[59, 89]}
                          h={[59, 89]}
                          bg={"transparent"}
                          border={'2px solid #0FCB44'}
                          src={GridCardIcon}
                          value2={"Last updated just now"}
                          value2Color={'#9B9A9A'}
                          showArrow={false}
                          sparkline={<Box> {/* Add sparkline component here */} </Box>}
                      />
                      <LatestValueCardLayout
                          title={'Grid Feed'}
                          deviceLabel='Grid-Meter'
                          telemetry='Grid_Daily_Energy_Import_kWh'
                          titleColor='#003F6B'
                          stat={'7kW'}
                          unit=' kWh'
                          width={["100%","100%",'80%']}
                          height='30%'
                          statColor={"#8842E0"}
                          w={[59, 89]}
                          h={[59, 89]}
                          bg={"transparent"}
                          border={'2px solid #0FCB44'}
                          src={GridCardIcon}
                          value2={"Last updated just now"}
                          value2Color={'#9B9A9A'}
                          showArrow={false}
                          sparkline={<Box> {/* Add sparkline component here */} </Box>}
                      />
              </GridItem>
              <GridItem h={"100%"} ml={[0, 0, -55]}>
                      <ChartLayout 
                          title='Line 1 Voltage'
                          width={["auto", "100%"]}
                          height={"45%"}
                          icon={GiThunderball}
                      >
                          <DigitalHorizontalBar value={(DigitalBarData[0]) ||0} />
                      </ChartLayout>
                      <ChartLayout 
                          title='Line 1 Amps'
                          width={["auto", "100%"]}
                          height={"45%"}
                          icon={GiThunderball}
                      >
                          <DigitalHorizontalBar value={(DigitalBarData[3]) ||0} />
                      </ChartLayout>
              </GridItem>
              <GridItem h={"100%"}>
                      <ChartLayout 
                          title='Line 2 Voltage'
                          width={["auto", "auto"]}
                          height={"45%%"}
                          icon={GiThunderball}
                      >
                          <DigitalHorizontalBar value={(DigitalBarData[1]) ||0} />
                      </ChartLayout>
                      <ChartLayout 
                          title='Line 2 Amps'
                          width={["auto", "auto"]}
                          height={"45%%"}
                          icon={GiThunderball}
                      >
                          <DigitalHorizontalBar value={(DigitalBarData[4]) ||0} />
                      </ChartLayout>
              </GridItem>
              <GridItem h={"100%"}>
                      <ChartLayout 
                          title='Line 3 Voltage'
                          width={["auto", "auto"]}
                          height={"45%"}
                          icon={GiThunderball}
                      >
                          <DigitalHorizontalBar value={(DigitalBarData[2]) ||0} />
                      </ChartLayout>
                      <ChartLayout 
                          title='Line 3 Amps'
                          width={["auto", "auto"]}
                          height={"45%"}
                          icon={GiThunderball}
                      >
                          <DigitalHorizontalBar value={(DigitalBarData[5]) ||0} />
                      </ChartLayout>
              </GridItem>
          </SimpleGrid>
          <SimpleGrid
              h='auto'
              maxW={"8xl"}
              minChildWidth={["280px", "450px"]}
              column={3}
              mt={-4}
            //   templateRows='repeat(2, 1fr)'
            //   templateColumns='1.5fr 1.5fr 1.5fr 2fr 1.5fr'
              gap={2}
          >
              <GridItem w={["100%", "100px","41%"]} h={"100%"}>
                  <ChartLayout
                      title='Grid Card'
                      width={["100%", "289px"]}
                      height={"280px"}
                      bg={`url(${BG}) no-repeat center/cover`}
                      icon={GridIcon}
                  >
                      <CalculationCard DataLabel={DataLabel} data={PlantCardData} />
                  </ChartLayout>
              </GridItem>
              <GridItem w={["100%", "100px", "100%","110%"]} h={"100%"} colSpan={1} ml={[0, 0, 0, 0, -175]}>
                  <ChartLayout
                      title='Grid Voltage & Hz'
                      width={["100%", "100%"]}
                      height={"280px"}
                      icon={FcLineChart}
                      timeWindow={true}
                      onTimeWindowChange={handleTimeWindowGridVoltChange}
                      onReset={GridVoltHandleReset}
                      fullScreen={true}
                  >
                      <LineChart height={230} apiData={GridVoltData || [{}]}
                        props={{
                            yAxis : {
                                title : {
                                    text : "Volts"
                                }
                            }
                        }}
                      
                      />
                  </ChartLayout>
              </GridItem>
              <GridItem w={["100%", "100px", "100%", "100%","127%"]} h={"100%"} colSpan={1} ml={[0,  0, 0, 0, -125]}>
                  <ChartLayout
                      title='Grid Power'
                      width={["100%", "100%"]}
                      height={"280px"}
                      icon={FcLineChart}
                      timeWindow={true}
                      onTimeWindowChange={handleTimeWindowGridPowerChange}
                      onReset={GridPowerHandleReset}
                      fullScreen={true}
                  >
                      <LineChart height={230} apiData={GridPowerData || [{}]} 
                        props={{
                            yAxis : {
                                title : {
                                    text : "kW / KVAR / KVA"
                                }
                            }
                        }}
                      />
                  </ChartLayout>
              </GridItem>
              <GridItem w={["100%","100%","100%","100%","87%"]} h={"100%"} mt={[0,0,-4]} colSpan={[1,1,1,1,2]}>
                  <ChartLayout
                      title='Grid Energy Flow'
                      width={["100%", "100%"]}
                      height={"280px"}
                      icon={FcLineChart}
                      timeWindow={true}
                      onTimeWindowChange={handleTimeWindowGridEnergyChange}
                      onReset={GridEnergyHandleReset}
                      fullScreen={true}
                  >
                      <ColumnChart height={230} apiData={GridEnergyData || [{}]} 
                        props={{
                            yAxis : [{
                                title : {
                                    text : "kWh"
                                },
                            },{
                                title : {
                                    text : "%"
                                },
                                opposite : true
                            }]
                        }}
                      />
                  </ChartLayout>
              </GridItem>
              <GridItem w={["100%","100%","100%","100%","126%"]} h={"100%"} mt={[0,0,-4]} ml={[0,0,-120]}>
                  <ChartLayout
                      title='Grid Current'
                      width={["100%", "100%"]}
                      height={"280px"}
                      icon={FcLineChart}
                      timeWindow={true}
                      onTimeWindowChange={handleTimeWindowGridCurrentChange}
                      onReset={GridCurrentHandleReset}
                      fullScreen={true}
                  >
                      <LineChart height={200} apiData={GridCurrentData || [{}]}
                        props={{
                            yAxis : {
                                title : {
                                    text : "Ampere"
                                }
                            }
                        }}
                      />
                  </ChartLayout>
              </GridItem>
          </SimpleGrid>
      </Box>
   );
};

export default GridDashboard;
