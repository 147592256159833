import { Box, Button, Flex, Popover, PopoverAnchor, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text } from "@chakra-ui/react";
import React from "react";

const NotificationPopup: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const data = [
        {
            "createdTime": 1726722993579,
            "subject": "Device was added",
            "text": "Device 'Durtek-Cal' was added by user info@cogneta.in",
        },
        {
            "createdTime": 1726722993579,
            "subject": "Device was added",
            "text": "Device 'Durtek-Cal' was added by user info@cogneta.in",
        },
        {
            "createdTime": 1726722993579,
            "subject": "Device was added",
            "text": "Device 'Durtek-Cal' was added by user info@cogneta.in",
        },
        {
            "createdTime": 1726722993579,
            "subject": "Device was added",
            "text": "Device 'Durtek-Cal' was added by user info@cogneta.in",
        },
        {
            "createdTime": 1726722993579,
            "subject": "Device was added",
            "text": "Device 'Durtek-Cal' was added by user info@cogneta.in",
        },
        {
            "createdTime": 1726722993579,
            "subject": "Device was added",
            "text": "Device 'Durtek-Cal' was added by user info@cogneta.in",
        },
        {
            "createdTime": 1726722993579,
            "subject": "Device was added",
            "text": "Device 'Durtek-Cal' was added by user info@cogneta.in",
        },

    ]
  return (
    <Popover placement="bottom" isLazy>
      <PopoverTrigger>
        <Box as="span" display="inline-block" mt={2}>
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent width="350px" height={"70vh"} overflow={"hidden"} backdropFilter={"blur(13px) saturate(180%)"} bgColor={"rgba(255, 255, 255, 0.75)"}>
        <PopoverArrow />
        <PopoverHeader>
            <Text fontSize={16} fontWeight={600} letterSpacing={0}>Notification</Text>
            <PopoverCloseButton />
        </PopoverHeader>
        <PopoverBody overflow={"auto"} scrollBehavior={"smooth"}>
          <Flex direction={"column"}>
            {data.map((value, i) => (
                <Box key={i} fontSize={14} borderBottom={"1px solid #999"} pt={3}>
                    <Text fontWeight={500}>{i+1}. {value.subject}</Text>
                    <Text fontWeight={400} pl={5}>{value.text}</Text>
                    <Text textAlign={"right"} fontSize={10} fontWeight={500} color={"#999"}>{new Date(value.createdTime).toLocaleDateString()}</Text>
                </Box>
            ))}
          </Flex>
        </PopoverBody>
        <PopoverFooter>
            <Button colorScheme="green" width={"100%"}>View All</Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationPopup;
