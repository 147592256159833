import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel, 
  Text,
  useColorModeValue,
  Flex,
  Button,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import PlantTable from '../../widgets/tables/PlantTable';
import { h, html } from 'gridjs';
import UsePlantTable, { UsePlantAssetTable } from '../../../Services/Hooks/UsePlantTable';
import UseAssetSummary from '../../../Services/Hooks/UseAssetSummary';
import UsePlantTableSummary from '../../../Services/Hooks/UsePlantTableSummary';
import PlantTableAPI, { PlantAssetTableAPI } from '../../../api/plantTableAPI';
import { MdFullscreen } from 'react-icons/md';

interface PlantTableLayoutProps {
  children: React.ReactNode;
  title: string;
  icon?: IconType;
  width: string[];
  height: string;
}




const PlantTableLayout: React.FC<PlantTableLayoutProps> = ({ children, title, icon, width, height }) => {
  // *********** FULL SCREEN ***************
  const [isFullscreen, setIsFullscreen] = useState(false);

  // *************** View Selection *************
  const [view, setView] = useState('customer')
  
  const [PVPlantToggle , setPVPlantToggle] = useState<boolean>(false);
  
  // *************PV PLANT*****************
  const customerPVCol = [
    {
      name : "Customer Name",
      width : 150, 
      formatter: (cell : any, row: any) => {
      return h('b', {
        style : {padding: "4px", marginLeft:"4px", borderLeft:`4px solid ${handleBorderColor(parseFloat(row.cells[2]?.data || -1))}`, cursor:  "pointer"},
        onClick : () => handlePVClick(cell[0])
      }, cell[1])
   }
   }, {name : "Type", width : 100, sort : true}, {name : "PV Power", width : 100, formatter : (cell : any) => cell == "-1" ? "NA" : cell}, {name : "PV Today kWh", width : 130}, {name : "Availability", width : 120}]
   
  const [PVColumn, setPVColumn] = useState<any[]>(customerPVCol);
  var PtextSearch = "PV";
  var PsearchTags = {
    calculation : "INV_Total_Power,AC Capacity,DG Make & Model,Country",
    "inverter-1" : "B1_Inverter_Inverter_1_AC_Active_Power_Watt,B1_Inverter_Inverter_1_Frequency_Hz"
  }
  let PVPlantData = ""
  // ***************PV***********
  var textSearch = "PV";
  var searchTags = {
    calculation : "INV_Total_Power,AC Capacity,DG Make & Model,Country",
    "inverter-1" : "B1_Inverter_Inverter_1_AC_Active_Power_Watt,B1_Inverter_Inverter_1_Frequency_Hz"
  }
  const PVData = UsePlantTable(searchTags, textSearch, "") as any;

  const [pv, setPV] = useState(PVData);
  useEffect(() => {
    if(PVData) {

      setPV(PVData)
    }
  }, [PVData]);

  const PlantPVCol = [
    {
      name : "Plant Name",
      width : 150, 
      formatter: (cell : any, row: any) => {
      return h('b', {
        style : {padding: "4px", marginLeft:"4px", borderLeft:`4px solid ${handleBorderColor(parseFloat(row.cells[5]?.data || -1))}`, cursor:  "pointer"},
        onClick : () => handlePVClick(cell[0])
      }, cell[1])
   }
   }, {name : "Type", width : 100, sort : true}, {name : "Energy System", width : 130}, {name : "Capacity", width : 100}, {name : "Country", width : 100}, {name : "PV Power", width : 100, formatter : (cell : any) => cell == "-1" ? "NA" : cell}, {name : "Irradiation", width : 100}, {name : "PV Today kWh", width : 130}, {name : "PR", width : 70}, {name : "Availability", width : 120}]
  
  const handlePVClick = async (id : string) => {
    PVPlantData = await PlantTableAPI(PsearchTags, PtextSearch, id);
    if(PVPlantData) {
      
      setPV(PVPlantData);
      setPVPlantToggle(true);
    }
  }

  function backToPVCustomer() {
    setPVColumn(PlantPVCol);
    setPV(PVData);
    setPVPlantToggle(false)
  }

  
  // **************Hybrid***************\
  const customerHybridCol = [
    {
      name : "Customer Name",
    width : 150, 
    formatter: (cell : any, row: any) => {
    return h('b', {
      style : {padding: "4px", marginLeft:"4px", borderLeft:`4px solid ${handleBorderColor(parseFloat(row.cells[2]?.data || -1))}`, cursor:  "pointer"},
      onClick : () => handleHybridClick(cell[0])
    }, cell[1])
 }
  }, {name : "Type", width : 100, sort : true}, {name : "PV Power", width : 100, formatter : (cell : any) => cell == "-1" ? "NA" : cell}, {name : "PV Today kWh", width : 130}, {name : "BESS Power", width : 120}, {name : "BESS Discharge kWh", width : 160}, {name : "DG Power", width : 100}, {name : "DG Today kWh", width : 130},{name : "PR", width : 70}, {name : "Availability", width : 120}]
  const [HybridColumn, setHybridColumn] = useState<any[]>(customerHybridCol);

  var CHtextSearch = "Hybrid";
  var CHsearchTags = {
    calculation : "INV_Total_Power",
    "inverter-1" : "B1_Inverter_Inverter_1_AC_Active_Power_Watt,B1_Inverter_Inverter_1_Frequency_Hz" 
  }
  const HybridData = UsePlantTable(CHsearchTags, CHtextSearch, "") as any;
  const [hybrid, setHybrid] = useState(HybridData);
  
  useEffect(() => {
    if(HybridData) {
      setHybrid(HybridData);
    }
  }, [HybridData])
  
  // **************Plant Hybrid***************
  const [HybridPlantToggle , setHybridPlantToggle] = useState<boolean>(false)
  var HtextSearch = "Hybrid";
  var HsearchTags = {
    calculation : "INV_Total_Power,AC Capacity,DG Make & Model,Country",
    "inverter-1" : "B1_Inverter_Inverter_1_AC_Active_Power_Watt,B1_Inverter_Inverter_1_Frequency_Hz" 
  }
  let HybridPlantData = ""
  const plantHybridCol = [
    {
    name : html(`<b title="Plant Name">Plant Name</b>`),
    width : 150, 
    formatter: (cell : any, row: any) => {
    return h('b', {
      style : {padding: "4px", marginLeft:"4px", borderLeft:`4px solid ${handleBorderColor(parseFloat(row.cells[7].data))}`, cursor:  "pointer"},
      onClick : () => handleHybridClick(cell[0])
    }, cell[1])
 }
  }, {name : html(`<b title="Type">Type</b>`), width : 70, sort : true}, {name : html(`<b title="Energy System">Energy System</b>`), width : 100},
  {name : html(`<b title="PV Capacity">PV Capacity</b>`), width : 120,
 formatter : (cell : any, row : any) => 
  html(`${row.cells[3].data}`)
},
   {name : html(`<b title="BESS Capacity">BESS Capacity</b>`), width : 120}, {name : html(`<b title="DG Capacity">DG Capacity</b>`), width : 120}, {name : html(`<b title="Country">Country</b>`), width : 100}, {name : html(`<b title="PV Power">PV Power</b>`), width : 100, formatter : (cell : any) => cell == "-1" ? "NA" : cell}, {name : html(`<b title="Irradiation">Irradiation</b>`), width : 100}, {name : html(`<b title="PV Today kWh">PV Today kWh</b>`), width : 130}, {name : html(`<b title="BESS Power">BESS Power</b>`), width : 120}, {name : html(`<b title="BESS Discharge kWh">BESS Discharge kWh</b>`), width : 160}, {name : html(`<b title="DG Power">DG Power</b>`), width : 100}, {name : html(`<b title="DG Today kWh">DG Today kWh</b>`), width : 130},{name : html(`<b title="PR">PR   ,</b>`), width : 100}, {name : html(`<b title="Availability">Availability</b>`), width : 120}]
  // SHORT VIEW
   const plantHybridColShortView = [
     {
     name : html(`<b title="Plant Name">Plant Name</b>`),
     width : 170, 
     formatter: (cell : any, row: any) => {
     return h('b', {
       style : {padding: "4px", marginLeft:"4px", borderLeft:`4px solid ${handleBorderColor(parseFloat(row.cells[7].data))}`, cursor:  "pointer"},
       onClick : () => handleHybridClick(cell[0])
     }, cell[1])
  }
   }, {name : html(`<b title="Type">Type</b>`), width : 70, sort : true}, {name : html(`<b title="Energy System">Energy System</b>`), width : 100},
   {name : html(`<b title="PV Capacity">PV Capacity</b>`), width : 120,
  formatter : (cell : any, row : any) => 
   html(`${row.cells[3].data}`)
 },
    {name : html(`<b title="BESS Capacity">BESS Capacity</b>`), width : 120}, {name : html(`<b title="DG Capacity">DG Capacity</b>`), width : 120}, {name : html(`<b title="Country">Country</b>`), width : 100}, {name : html(`<b title="PV Power">PV Power</b>`), width : 100, formatter : (cell : any) => cell == "-1" ? "NA" : cell}, {name : html(`<b title="Irradiation">Irradiation</b>`), width : 100}, {name : html(`<b title="PV Today kWh">PV Today kWh</b>`), width : 130}]
  const handleHybridClick = async (id : string) => {
    if(isFullscreen) {
      setHybridColumn(plantHybridCol);
    } else {
      setHybridColumn(plantHybridColShortView);
    }
    HybridPlantData = await PlantTableAPI(HsearchTags, HtextSearch, id) as any;
    if(HybridPlantData) {
      setHybrid(HybridPlantData)
      setHybridPlantToggle(true);
    }
  }
  function backToHybridCustomer() {
    setHybridColumn(customerHybridCol);
    setHybrid(HybridData);
    setHybridPlantToggle(false);
  };
  const PVPlantAssetData = UsePlantAssetTable(PsearchTags, PtextSearch);
  const HybridPlantAssetData = UsePlantAssetTable(HsearchTags, HtextSearch);

  // ***************** ASSET GRP PLANT Fetch ***************
  const TableSetUp = async () => {
    switch (view) {
      case "customer":
        setPVColumn(customerPVCol);
        setPV(PVData)
        setHybridColumn(customerHybridCol);
        setHybrid(HybridData);
        
      break;
        
      case "plant" :
        setPVColumn(PlantPVCol);
        setPV(PVPlantAssetData);
        if(isFullscreen) {
          setHybridColumn(plantHybridCol);
        } else {
          setHybridColumn(plantHybridColShortView);
        }
        setHybrid(HybridPlantAssetData);
      break;
    
      default:
        break;
    }
  }
  useEffect(() => {
    TableSetUp();
  }, [view, isFullscreen]);
  
  // *****************Summary*************
  var textSearchSummary = ["", "PV", "Wind", "Hybrid"];
  var searchTagsSummary = {
    calculation : "INV_Total_Power"
  }
  const PlantTableSummaryData = UsePlantTableSummary(searchTagsSummary, textSearchSummary) || [];

  const stateNumber : number[] = PlantTableSummaryData[0] || [0,0,0] as number[];
  const PVSummary = PlantTableSummaryData[1] || [0,0,0,0] as number[];
  const WindSummary = PlantTableSummaryData[2] || [0,0,0,0] as number[];
  const HybridSummary = PlantTableSummaryData[3] || [0,0,0,0] as number[];


  const stateColor = ["#13CD26", "#CB0511", "#837F97"];
  
  const tabList = ["PV", "Wind", "Hybrid"];
  const dotColor = ["#0086CC", "#F8931F", "#704199"] // "#7EC800"
  
  
  const handleBorderColor = (data : number) => {
    if(data > 0) {
      return "#13CD26";
    } else if(data == 0) {
      return "#CB0511";
    } else {
      return "#837F97";
    }
  }


  // *****************Full Screen**********

  const tableRef = useRef(null);
  const [paginationLimit, setPaginationLimit] = useState<number>(10);

  // console.log(apiData)

  const handleFullScreen = () => {
    if (tableRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else {
        (tableRef.current as HTMLElement)?.requestFullscreen();
        setIsFullscreen(true);
        setPaginationLimit(20); // Increase pagination limit in fullscreen mode
      }
    }
  };

  useEffect(() => {

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setPaginationLimit(10);
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullscreen]);

  return (
    <Box
      ref={tableRef}
      w={width}
      h={height}
      borderRadius="5px"
      bg={useColorModeValue('white', 'gray.800')}
      p={3}
      mt={5}
      boxShadow="md"
      overflow={"auto"}
    >
      
          <Tabs variant='solid-rounded' colorScheme='gray'>
            <TabList>
                <HStack mr={5}>
                    <Icon as={icon} boxSize={5} color={useColorModeValue('#004F86', 'white')}/>
                    <Text 
                    fontSize={"xs"}
                    fontFamily={"inter"} 
                    fontWeight={600} 
                    color={useColorModeValue('#004F86', 'white')}
                    letterSpacing={1}
                    pl={3}
                    >
                        {title}
                    </Text>
                </HStack>
                {tabList.map((value, index) =>(
                  <Tab 
                  mx={1} 
                  px={3}
                  fontSize={"xs"}
                    >
                      <span 
                        style={{width:"10px",height:"10px",backgroundColor:dotColor[index], display:"flex", borderRadius:"50%", marginRight:"7px"}}
                        ></span>
                      <Text>{value}</Text> &nbsp; <sub><b style={{color : "red"}}>{PlantTableSummaryData[index+1]?.[2] || 0}</b>/{PlantTableSummaryData[index+1]?.[0] || 0}</sub>  
                  </Tab>
                ))}

                <Flex ml={4} bg={"#EBEBEB"} borderRadius={"15%"} px={2}>
                  {stateNumber.map((value, index) => (
                  <HStack>
                    <span
                      style={{width:"12px",height:"12px", borderRadius:"2px",backgroundColor:stateColor[index], color:"#5A5A5A"}}
                    ></span>
                    <Text ml={-1} mr={2}>{value}</Text>
                  </HStack>
                  ))}
                </Flex>
                <Flex mt={3} ml={4} mr={4}>
                  <RadioGroup onChange={setView} value={view} size={"sm"} colorScheme='green'>
                    <Stack direction='row'>
                      <Radio value='customer'><Text fontSize={"10px"}>Customer view</Text></Radio>
                      <Radio value='plant'><Text fontSize={"10px"}>Plant view</Text></Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>
            </TabList>
            <TabPanels>
                <TabPanel overflow={"visible"}>
                  {PVPlantToggle && 
                <Button mb={2} onClick={backToPVCustomer} >Back</Button>}
                    <PlantTable
                     key={1}
                     fullScreen={false}
                     width='100%'
                     column={PVColumn}
                     apiData={pv || []}
                     paginationLimitProps={10}
                    />
                </TabPanel>
                <TabPanel key={2} overflow={"visible"}>
                    <PlantTable 
                      search={true}
                      autoWidth={false}
                      width='100%'
                      fullScreen={false}
                      paginationLimitProps={10}
                    />
                </TabPanel>
                <TabPanel key={3} overflow={"visible"}>
                  {HybridPlantToggle && 
                  <Button onClick={backToHybridCustomer} mb={2}>Back</Button>}
                    <PlantTable 
                      column={HybridColumn}
                      apiData={hybrid || []}
                      fullScreen={false}
                      paginationLimitProps={10}
                      autoWidth={true}
                      width='100%'
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
        <Icon 
        as={MdFullscreen}
        boxSize={5} 
        float={"right"} 
        pos={"relative"} 
        bottom={"32px"} 
        onClick={handleFullScreen}
        _fullScreen={{
          bottom : "35px"
        }}
        cursor={"pointer"}
        />
    </Box>
  );
};

export default PlantTableLayout;
