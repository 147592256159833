import {
 Box,
 VStack ,
 Text,
 Table,
 Tbody,
 Tr,
 Td,
 Flex,
 HStack,
 Icon,
 Stack,
} from "@chakra-ui/react";
import ChartLayout from "../Layouts/ChartLayouts/ChartLayout";
import BatteryState from "../../assets/BESS/Overview/BatteryState";
import SparkBarChart from "../widgets/charts/SparkBarChart";
import { CiTempHigh } from "react-icons/ci";
import { BsFillLightningChargeFill } from "react-icons/bs";
import PieChart from "../widgets/charts/PieChart";
import React, { useEffect, useState } from "react";
import { useTimeHandle } from "../../Services/TimeWindowSetting";
import UseBESSDaily from "../../Services/Hooks/Battery/UseBESSDaily";
import LineChart from "../widgets/charts/LineChart";

interface StateOfBatteryProps {
    data : string[];
}

export const StateOfBattery : React.FC<StateOfBatteryProps> = ( {data} ) => {
    
    const tableRows = [["Units Available", data[0]], 
                        ["Chargeable Energy ", `${data[1]}kW`],
                        ["Units Running", data[2]],
                        ["Reactive Power", `${data[3]}MVAR`],
                        ["Chargeable Power", `${data[4]}kW`],
                        ["Dischargeable Energy", `${data[5]}kWh`],
                        ["Dischargeable Power", `${data[6]}kW`],
                        ["Apparent Power", `${data[7]}MVA`],
                        ["Active power", `${data[8]}MW`], 
                        ["Grid Forming Voltage Slew", `${data[9]}%/s`]];
    return (
        <ChartLayout
            title="State Of Battery"
            width={["320px", "350px"]}
            height="780px"
        >
            <VStack>
                <Box 
                    width={"60%"}
                    my={10}
                >
                    <BatteryState
                        batteryPercentage={60}
                    />
                </Box>
                <Table variant={"simple"}>
                    <Tbody>
                    {tableRows.map((_, i) => {
                        if (i % 2 === 0 && i + 1 < tableRows.length) {
                            return (
                            <Tr key={i}>
                                <Td 
                                    borderBottom={"none"}
                                    borderTop={"1px solid #C1C1C1"}
                                    borderRight={"1px solid #C1C1C1"}
                                    fontFamily={"inter"}
                                    fontWeight={500}
                                    fontSize={[10, 12]}
                                    color={"#000000"}
                                    letterSpacing={1}
                                >
                                <VStack align={"left"} spacing={0}>
                                    <Text>{tableRows[i][0]}</Text>
                                    <Text
                                        fontSize={[12, 17]}
                                        fontWeight={600}
                                        color={"#03BB7D"}
                                    >
                                    {tableRows[i][1]}
                                    </Text>
                                </VStack>
                                </Td>
                                <Td 
                                    borderBottom={"none"}
                                    borderTop={"1px solid #C1C1C1"}
                                    fontFamily={"inter"}
                                    fontWeight={500}
                                    fontSize={[10, 12]}
                                    color={"#000000"}
                                    letterSpacing={1}
                                >
                                <VStack align={"left"} spacing={0}>
                                    <Text>{tableRows[i+1][0]}</Text>
                                    <Text
                                        fontSize={[12, 17]}
                                        fontWeight={600}
                                        color={"#03BB7D"}
                                    >
                                    {tableRows[i+1][1]}</Text>
                                </VStack>
                                </Td>
                            </Tr>
                            );
                        }
                        return null;
                        })}

                    </Tbody>
                </Table>
            </VStack>
        </ChartLayout>
    );
};

// Some Title Will Change in Future
interface data {
    bar : number[];
    temp : number;
    volt : number
}

interface someTitleProps { 
    apiData? : data[];
}
export const SomeTitle : React.FC <someTitleProps> = ( {apiData = [{bar : [0,0,0], temp : 0, volt : 0}]} ) => {
    
    return (
        <ChartLayout
            title="Some Title"
            width={["full", "400px"]}
            height = "200"
            overflow="auto"
        >
            <Box mt={5}>
                <hr style={{color : "#D7D7D7"}} />
                <Flex 
                    flexDirection={"column"}
                    justifyContent={"space-around"}
                >
                    {apiData.map((_, i) => (
                    <HStack 
                        key={i}
                        border={"1px solid #E4E4E4"} 
                        borderRadius={5} fontFamily={"inter"}
                        alignItems={"center"}
                        spacing={5}
                        px={5}
                        mt={2}
                    >
                        <Text
                            fontSize={12}
                            fontWeight={600}
                            color={"#4B4B4B"}
                        >S0{i+1}.M01</Text>
                        <SparkBarChart apiData={apiData[i].bar || [22,2,22]}/>
                        <HStack spacing={1} mr={3}>
                            <Icon as={CiTempHigh} />
                            <Text
                                fontSize={12}
                                fontWeight={600}
                                color={"#657079"}
                                >{apiData[i].temp || 0}°C</Text>
                        </HStack>
                        <HStack spacing={1}>
                            <Icon as={BsFillLightningChargeFill} boxSize={"10px"} />
                            <Text
                                fontSize={12}
                                fontWeight={600}
                                color={"#657079"}
                                >{apiData[i].volt || 0} V</Text>
                        </HStack>
                    </HStack>
                    ))}
                </Flex>
            </Box>
        </ChartLayout>
    );
};

export const BatteryBank = () => {
    return (
        <ChartLayout 
            title="Bank"
            width={["full", "400px"]}
            height="330"
        >
            <HStack spacing={0} flexDirection={["column", "row"]}>
                <Box zIndex={2} w={200}>
                    <PieChart />
                </Box>
                <VStack
                    fontFamily={"inter"}
                    fontWeight={600}
                    zIndex={1}
                >
                    <Text
                        color={"#042441"}
                        padding={3}
                        borderBottom={"1px solid #999"}
                        fontSize={[14, 12]}
                    >MODULE TEMPERATURE </Text>
                    <HStack spacing={2} color={"#000000"} borderBottom={"1px solid #999"} px={"16%"}>
                        <Text
                            py={3}
                        >Min.</Text>
                        <Text
                            py={3}
                            fontSize={[12, 18]}
                        >31.32°C </Text>
                    </HStack>
                    <HStack spacing={2} color={"#000000"} borderBottom={"1px solid #999"} px={"16%"}>
                        <Text
                            py={3}
                        >Max.</Text>
                        <Text
                            py={3}
                            fontSize={[12, 18]}
                        >35.42°C </Text>
                    </HStack>
                    <HStack mt={4}>
                        <VStack
                            borderLeft={"3px solid #03BB7D"}
                            spacing={0}
                            align={"center"}
                        >
                            <Text
                                color={"#000000"}
                                padding={3}
                            >Connected </Text>
                            <Text
                                color={"#000000"}
                            >9 </Text>
                        </VStack>
                        <VStack
                            borderLeft={"3px solid #FA0505"}
                            spacing={0}
                        >
                            <Text
                                color={"#000000"}
                                padding={3}
                            >Disabled </Text>
                            <Text
                                color={"#000000"}
                            >1 </Text>
                        </VStack>
                    </HStack>
                </VStack>
            </HStack>
        </ChartLayout>
    );
};

export const PCS = () => {
    
    // ********************* Inverter Daily Energy *********************
    const {
        timeWindow: timeWindowInverterDailyEnergy,
        handleTimeWindowChange: handleTimeWindowInverterDailyEnergyChange,
        handleReset: InverterDailyEnergyHandleReset
    } = useTimeHandle(1, "cdsf", "AVG", [1, "hour"]);
    
    
    var searchTagInverterDailyEnergy = { 
        devName : "Inverter-1",                  //"Inverter-1", // cal
        keys : "B1_Inverter_Inverter_1_AC_Active_Power_Watt,B1_Inverter_Inverter_1_AC_Apparant_Power_VA,B1_Inverter_Inverter_1_AC_Reactive_VAr",         //"B1_Inverter_Inverter_1_Energy_Daily_kWh",
        type : ["spline", "spline", "spline"],
        name : ["Daily Energy", "Apparent Power", "Reactive Power"]
    };
    const pcsColor = ["#7F5B9F", "#C02626", "#3FB1B9"]
    const InverterDailyEnergyData : any = UseBESSDaily(searchTagInverterDailyEnergy, timeWindowInverterDailyEnergy)?.map((series : object, index : number) => ({
        ...series,
        color : pcsColor[index]
    }));;
    const count = [1,2,3,4,5,6,7,8,9,10];
    return (
        <ChartLayout
            title="PCS"
            width={["full", "100%"]}
            height={"500px"}
            fullScreen={true}
        >
            <Flex direction="row" wrap={"wrap"} gap={1}>
                {
                    count.map((value, i) => (
                        <ChartLayout
                            title={`PCS ${i+1}`}
                            width={["full", "370px"]}
                            height={"205px"}
                            border="1px solid #EEEEEE"
                            mt="-10"
                        >
                            <HStack>
                                <Box borderRight={"1px solid black"} width={"50%"} height={"100%"}>
                                    <VStack fontSize={"16px"} fontFamily={"inter"} fontWeight={500}>
                                        <VStack>
                                            <Text>MCCB State</Text>
                                            <svg width="42" height="10" viewBox="0 0 42 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 9 9.65039)" fill={"red"}/>
                                                <circle cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 29 9.65039)" fill={"red"}/>
                                                <path d="M12 7.65026C14.7742 1.8657 24.5 -3.84961 33 7.65026" stroke={"red"}/>
                                                <path d="M1 6.65039H9" stroke={"red"} stroke-linecap="round"/>
                                                <path d="M33 6.65039H41" stroke={"red"} stroke-linecap="round"/>
                                            </svg>
                                        </VStack>
                                        <VStack spacing={0}>
                                            <Text>Current</Text>
                                            <Text color={"#4A4A4A"} fontWeight={200}>678 A</Text>
                                        </VStack>
                                        <VStack spacing={0}>
                                            <Text>Voltage</Text>
                                            <Text color={"#4A4A4A"} fontWeight={200}>6778 kW</Text>
                                        </VStack>
                                    </VStack>
                                </Box>
                                <LineChart
                                    props={
                                                {title : 
                                                    {text : ""},
                                                    xAxis : 
                                                    {visible : false},
                                                    yAxis : 
                                                    {visible : false},
                                                    legend : {enabled : true, align : "center", itemStyle : {fontSize : "10px"}, layout : "horizontal"}, 
                                                    exporting : {enabled : false},
                                                    chart : {
                                                        height : 230,
                                                        width : 170
                                                    }
                                                }
                                            }
                                    apiData={InverterDailyEnergyData || [{}]}
                                />
                            </HStack>
                        </ChartLayout>
                    ))
                }
            </Flex>
        </ChartLayout>
    );
};