import * as React from "react";
const GridIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    viewBox="20 10 30 80"
    fill="none"
  >
    <path
      clipRule="evenodd"
      d="m29.69 24.2-.01.01a3.4 3.4 0 0 0-1 2.4v27.13l-8.06 26.99a.93.93 0 0 0 .63 1.15.93.93 0 0 0 1.16-.63l2.95-9.86h22.42l2.94 9.86a.93.93 0 0 0 1.79-.52l-8.06-26.99V26.61a3.41 3.41 0 0 0-3.42-3.4H32.1a3.4 3.4 0 0 0-2.41.99m-2.64 45.34h19.03l-9.51-8.69zm10.89-9.95 8.67 7.93-3.72-12.46zm-11.43 7.93 8.68-7.93-4.96-4.53-3.72 12.45zM32 54.16l4.56 4.17 4.56-4.16zm-.24-1.85h9.38l-4.69-4.99zm5.97-6.35 4.85 5.17V40.8l-4.85 5.17zm-7.18 4.93 4.63-4.93-4.63-4.92zm1.21-11.28 4.69 4.99 4.7-4.99zm0-1.85h9.39l-4.69-5zm10.79-11.48-4.81 5.12 4.84 5.18v-9.97q0-.17-.03-.33m-11.99.16q-.01.08-.01.17v9.73l4.64-4.94-4.63-4.95zm10.58-1.37-.11-.01H32.1q-.13 0-.27.03l4.64 4.95z"
      fill="#415075"
      fillRule="evenodd"
    />
  </svg>
);
export default GridIcon;
